<template>
  <ValidationProvider
    tag="div"
    class="question single-answer column is-half"
    :rules="validationRules"
    v-slot="{ errors }"
  >
    <div class="question-text">
      <p class="help is-danger" v-if="errors[0]">{{ errors[0] }}</p>
    </div>
    <div class="question-content card">
      <div class="card-content">
        <b-field>
          <b-datetimepicker
            :placeholder="$t('datetime.placeholder')"
            icon="calendar-today"
            :locale="$t('locale')"
            horizontal-time-picker
            v-bind="{ ...customizedProps }"
            @input="onChange"
            v-model="data"
            append-to-body
            :mobile-native="false"
          >
          </b-datetimepicker>
        </b-field>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import { QuestionMixin } from "@/mixins/question";
import { UnFocusMixin } from "@/mixins/unfocus";
export default {
  name: "DatetimePicker",
  mixins: [QuestionMixin, UnFocusMixin],
  data() {
    return {
      data: null,
    };
  },
  methods: {
    onChange(value) {
      this.$emit("input", { [this.question.qid]: value?.toISOString() });
    },
  },
  created() {
    setTimeout(() => {
      this.unFocus();
      const elmSurvey = document.getElementById("survey");
      const width = elmSurvey.offsetWidth;
      if (elmSurvey && width > 768) {
        elmSurvey.animate(
          [
            { height: "120px", opacity: 0 },
            { height: "50vh", opacity: 1 },
          ],
          {
            duration: 300,
            fill: "both",
          }
        );
      }
      const value = this.value[this.question.qid];
      if (value) {
        this.data = new Date(value);
        this.unFocus();
      }
    }, 300);
  },
};
</script>
